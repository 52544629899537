import * as styled from "./styled";
import Typewriter from "typewriter-effect";

const typed = "Are you in control of your email?";
const typed2 = "Or ";
const typed3 = " is it in control of you?";
const newLine = "<BR>";

const typeOptions = {
  skipAddStyles: true,
  delay: 30,
};

export const ScreenIntro = (props: any) => {
  return (
    <styled.IntroScreen>
          <Typewriter
      options={typeOptions}
      onInit={(typewriter) => {
        typewriter
          .start()
          .pauseFor(1000)
          .typeString(typed)
          .pauseFor(250)
          .typeString(newLine)
          .pauseFor(250)
          .typeString(newLine)
          .pauseFor(500)

          .typeString(typed2)
          .pauseFor(500)
          .typeString(typed3)
          .pauseFor(2000)
          .callFunction(() => {
            props.history.push('mission', {from: "intro"});
          })
        }} 
  />

    </styled.IntroScreen>
  );
}
