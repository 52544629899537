import * as styled from "./styled";
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import ReCAPTCHA  from "react-google-recaptcha";
import {useState} from "react";
import ReactLoading from 'react-loading';

emailjs.init('user_kYdTzLxjD2vUNXtICsQct');
const recaptchaSiteKey = "6LeZYl4bAAAAAO5_zZUuSxkx3k3GjhaOUYvXYe3E"

const MAX_TEXT_AREA_LENGTH = 1000;
const MAX_TEXT_LENGTH = 200;

enum RecaptchaState {
  UNCHECKED,
  NOT_SUBMITTED,
  SUBMITTED,
  SUBMITTED_SUCCESS,
  SUBMITTED_ERROR
}

export const ScreenNotify = (props: any) => {

/** 
  * Returns a form which inputs email address and redirects to confirm page
  *
  *  @remarks
  *  implement captcha which disables submission conditionally
  *  if recaptcha renders itself before form component is mounted, then an error exists, therefore
  *  useEffect hook is added to only show recaptcha after mount

*/

  const [recaptchaState, setRecaptchaState] = useState(RecaptchaState.UNCHECKED);

  const { register, handleSubmit, formState: { errors}} = useForm();

  const onRecaptchaChange = function(value:any) {
    setRecaptchaState( value ? RecaptchaState.NOT_SUBMITTED : RecaptchaState.UNCHECKED );
  }

  const onSubmit = (data: any) => {
    if (recaptchaState !== RecaptchaState.NOT_SUBMITTED) {
      return;
    }
    setRecaptchaState(RecaptchaState.SUBMITTED);

    emailjs.sendForm('service_isr2g4j', 'template_4rxkfrb', '#contact-form')
    .then(result => {
        setRecaptchaState(RecaptchaState.SUBMITTED_SUCCESS);
    })
    .catch(error => {
      setRecaptchaState(RecaptchaState.SUBMITTED_ERROR)
    });

  };


  const hasError = (id: string) => errors[id] ? "true" : "false";

  const Loading = () => {
    return (
      recaptchaState === RecaptchaState.SUBMITTED
      ? <styled.Overlay>
          <ReactLoading type="spin" color="#e01e5aff" height="7em" width="7em" />
        </styled.Overlay>
      : null
    );
  }


  const EmailForm = (
    <form id="contact-form" onSubmit={handleSubmit(onSubmit)} style={{opacity: recaptchaState === RecaptchaState.SUBMITTED ? 0 : 1}}>

      <styled.Row>
        <styled.Label>Get Notified</styled.Label>
        <input type="email" {...{...register('email', {required: true, maxLength: 100})}} maxLength={MAX_TEXT_LENGTH} aria-invalid={hasError('email')} placeholder="Antiquated Email"/>
      </styled.Row>

      <styled.Row>
            <textarea {...register('comments', {maxLength: MAX_TEXT_AREA_LENGTH})}  maxLength={MAX_TEXT_AREA_LENGTH} cols={10} rows={10} placeholder="Comments"/>
      </styled.Row>

      <styled.Row style={{marginBottom:'.5em'}}>
      <ReCAPTCHA
        sitekey={recaptchaSiteKey}
        onChange={onRecaptchaChange}
      />

      </styled.Row>
  
      <styled.Row>
        <button type="submit" value="Submit" disabled={recaptchaState===RecaptchaState.UNCHECKED || recaptchaState===RecaptchaState.SUBMITTED}>Submit</button>
      </styled.Row>
    </form>
    );

    const ErrorMsg = () => {
      return (
        <>
        <h1>
          Whoops!
        </h1>
        <div>
          Unfortunately, something is wrong with our back-end contact form service provider.  Please email us directly at <a href="mailto: info@ctrl.email">info@ctrl.email</a>
        </div>
        </>
      );
    }

    const renderStatefully = () => {
      switch(recaptchaState) {
        case RecaptchaState.SUBMITTED_SUCCESS:
          props.history.push('/confirmation');
          return;
        case RecaptchaState.SUBMITTED_ERROR:
          return <ErrorMsg />;
        default:
          return <>
            <Loading />
            {EmailForm}
            </>
      }
    }

  return (
    <styled.NotifyScreen>
      {renderStatefully()}
    </styled.NotifyScreen>
  )
}