import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Custom Hook to be used in a child component of React Router Parent Element
 *
 * scrolls the window position to the very top upon detecting change to the pathname
 *
 * use useLocation vs useHistory hook from library because it handles back/fwd button clicks better.
 */

interface RouteHandlerHookArgs {
  customHref?: string,
  customPathname?: string,
}

export const useNewRouteHandler = (args?: RouteHandlerHookArgs) => {
  const { pathname } = useLocation();
  const rawPathname = pathname.replace(/^\//, '');
  const capitalizedRawPathname = rawPathname.length ? rawPathname[0].toUpperCase() + rawPathname.slice(1) : 'Main';
  useEffect(() => {
    window.scrollTo(0, 0);
    (window as any)?.gtag({
      event: "pageview",
      page_location: args?.customHref || window.location.href,
      page_path: args?.customPathname || pathname,   
      page_title: args?.customPathname || capitalizedRawPathname  
    });
    document.title = rawPathname.length ? `${capitalizedRawPathname}` : 'Email Redesigned';
  }, [pathname, args?.customHref, args?.customPathname, capitalizedRawPathname,rawPathname.length]);
 
};
