import styled from "styled-components";
import {Link} from "react-router-dom"

const HeaderDiv = styled.header`
  position: fixed;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  width: 100%;
  height: 80px;
  background: #282c34;
  z-index: 1;
`;

export const Header = (): React.ReactElement => {

  return (
    <HeaderDiv>
      <Link to ="/mission"><img src="/header_logo.svg" alt="logo" style={{ height: "36px", width: "100px"}}  /></Link>
    </HeaderDiv>
  );
};
