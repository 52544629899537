import * as styled from "./styled";
import Typewriter from "typewriter-effect";

const typed = "Are you in control of your email?";
const typed2 = "Or is it in control of you?";

const scam1 = "<span style='font-size: 1.2em; color: #e01e5aff'>Do you know</span> who all has your email address";
const newline = "<br>";
const scam2 = "and how and when they plan to use it?";

const spam1 = "<span style='font-size: 1.2em; color: #e01e5aff'>Do you trust</span> the companies you share your email with"
const spam2 = "to not sell your email to other companies"
const spam3 = "to not get hacked and leak your email in the process"
const spam4 = "and to leave you alone once you unsubscribe?"

const clutter1 = "<span style='font-size: 1.2em; color: #e01e5aff'>Do you trust</span> even the people you know"
const clutter2 = "to not clutter your inbox"
const clutter3 = "and to not share your email without your knowledge?";

const freaks = `<span style="font-size: 1.2em">W</span>e are <img style="
  height: 26px;
  top: 10px;
  margin-bottom: -6px ;
  margin-right: 2px;
" src="/logo.svg" alt="control" /> <span style="
color: #e01e5aff;
margin-left:-.75em;
font-weight:800;
font-size: .8em;
font-family: "Helvetica Neue"; 
"
>freaks</span>`
const freaks2 = ` and <span style='font-size: 1.2em; color: #e01e5aff'>we don't.</span>`;
const freaks3 = ` Should you?`;


const typeOptions = {
  skipAddStyles: true,
  delay: 1,
};

const basePause = 500;

export const ScreenIssues = (props: any) => {
  return (
    <styled.IntroScreen>
        <span style={{textAlign: 'center'}}>{typed}</span>
        <span style={{textAlign: 'center'}}>{typed2}</span>
        <br></br>
      <Typewriter
      options={typeOptions}
      onInit={(typewriter) => {
        typewriter
          .start()

          .pauseFor(basePause)

          /* scam question */
          .typeString(scam1)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)
          .pauseFor(basePause)
          .typeString(scam2)
          .pauseFor(basePause*2)
          
          /* line spacing */

          .typeString(newline)
          .pauseFor(250)
          .typeString(newline)
          .pauseFor(250)

          /* spam question */
          .typeString(spam1)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)
          .typeString(spam2)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)
          .typeString(spam3)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)
          .typeString(spam4)
          .pauseFor(basePause*2)

          /* spacing */
          .typeString(newline)
          .pauseFor(250)
          .typeString(newline)
          .pauseFor(250)

          /* friend clutter question  */
          .typeString(clutter1)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)
          .typeString(`  ${clutter2}`)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)
          .typeString(`  ${clutter3}`)
          .pauseFor(basePause*2)

          /* spacing */
          .typeString(newline)
          .pauseFor(250)
          .typeString(newline)
          .pauseFor(250)
          .typeString(newline)
          .pauseFor(250)

          /* who we are  */

          .typeString(freaks)
          .pauseFor(basePause*1)
          .typeString(freaks2)
          .pauseFor(basePause*1.5)
          .typeString(freaks3)
          .pauseFor(basePause)

          /* spacing  */

          .typeString(newline)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)
          .typeString(newline)
          .pauseFor(basePause)

          /* link to notification  */
          .typeString(`Beta launch in 2022.`)
          .pauseFor(basePause*1)
          .typeString(` <a style="font-size:1.2em;" href="notify">Get notified</a>.`);
        }} 
  />

    </styled.IntroScreen>
  );
}
