import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle<{ open?: boolean }>`
  
  html {
    height: 100%;
    margin: 0;
    padding:0;
    display: block;
  }

  body {
    margin:0;
    padding:0;  
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: #373434;
    background: #282c34;
    color: #451735;
    cursor: auto;
    font-family: Poppins,Archivo, Arial,Helvetica,sans-serif;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.444;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-rendering: optimizeLegibility;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }
  `;
