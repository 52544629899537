import styled from "styled-components";
import {Link as RouterLink} from "react-router-dom";


const WIDTH = {
    MOBILE: "300px",
    TABLET: "600px",
    TABLET_XL: "900px",
    DESKTOP: "1024px",
  };

export const Main = styled.div`
    padding:80px 0px 0px 0px;
    height: 100%;
    width: 100%;
`;

export const IntroScreen = styled.div`

  overflow: hidden;
  font-weight: normal;
  font-family: 'Courier New';
  font-size: 14px;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 60px 5px;

  @media only screen and (min-width: ${WIDTH.MOBILE} ) {
    padding: 60px 15px;
  }

  @media only screen and (min-width: ${WIDTH.TABLET} ) {
    padding: 60px 30px;
    font-size: 16px;
  }

  @media only screen and (min-width: ${WIDTH.TABLET_XL} ) {
    padding: 60px 45px;
    font-size: 18px;
  }

  @media only screen and (min-width: ${WIDTH.DESKTOP} ) {
    padding: 60px;
    font-size: 20px;
  }

  a {
      font-size:1.3em;
      border-bottom: 1px solid #e01e5aff;
      cursor: pointer;
      text-decoration: none;
      color: #e01e5aff;
      &:hover {
        border-width: 2px;
      }
  }

  .Typewriter__cursor {
    animation:blinkText 1s infinite; 

    @keyframes blinkText {
        from, to { opacity: 0 }
        50% { opacity: 1; }
    }
  }
  .Typewriter {
    text-align: center;
  }
  .Typewriter__wrapper {
    display: inline;
    justify-content: center;
    color: white;    
  }
`;


export const SecondScreen = styled(IntroScreen)`

  white-space: pre-wrap;  
  color: white;
  font-family: Poppins;
  font-weight: 200;
  position: relative;
  line-height: 2.25em;
  display: 'flex';
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    height: 26px;
    top: 10px;
    margin-bottom: -6px ;
    margin-right: 2px;
  }

  span {
    text-align: center;
  }
`;

export const Spacer = styled.div`

  height: 4em;

  @media only screen and (min-width: 400px) {
    height: 3em;
  }
`;

export const SpacerSingle = styled.div`


@media only screen and (max-width: ${WIDTH.MOBILE} ) {
  height: 1em;
}
  height: .5em;
`;

export const BoldSpan = styled.span`
  font-size: 1.3em;
`;
export const BoldSpan2 = styled.span`
  font-size: 1.4em;
`;

export const Link = styled(RouterLink)`

`;

export const LogoColorSpan = styled.pre`
  color: #e01e5aff;
  font-weight:800;
  font-size: .9em;
  font-family: "Helvetica Neue";
  display: inline-block;
  margin: 0;
`;


export const SmallSpan = styled.span`
  font-size: .75em;
  display: block;
  margin-bottom: 1.2em;
`;



interface ShowProps {
  show: boolean
}

export const StagedSpan = styled.span<ShowProps>`
    opacity: ${({show}) => show ? '1' : '0'};
    transition: opacity 3000ms;
`;

export const NotifyScreen = styled(IntroScreen)`

  width: 100%;
`;

export const Row = styled.div`
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
 
  @media only screen and (max-width: ${WIDTH.MOBILE} ) {
    width: 90vw;
  }

  input,button {
    padding: 5px;

    font-family: "Helvetica Neue";

    text-align: left;
    width: 100%;
    height:2em;
    border-radius: 4px;
    border: none;
  }


  button {
    color: white;
    background-color: #e01e5aff;
    height: 2.2em;
    text-align: center;
    font-size:.8em;
    font-weight: bold;
    cursor: pointer;
    &:disabled {
      color: grey;
      cursor: default;
    }
    width: 100%;
  }

  textarea {
    font-family: "Helvetica Neue";

    padding: 5px;
    resize:none;
    text-align: left;
    width: 100%;
    border: none;
    border-radius: 4px;

    width: 100%;

  }
`;

export const Label = styled.div`

  font-family: "Helvetica Neue";
  margin-bottom:1em;
  font-size: 1.2em;

`;


export const Overlay = styled.div`
  position: absolute;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
`;

export const ConfirmScreen = styled(IntroScreen)`

  span {
    margin-bottom: 2em;
    text-align: center;
  }
  
  a {font-size: 1em;}
    
`;