import * as styled from "./styled";
import {useState,useEffect} from "react";

const regTimes = [500, 1250, 2500,4000,5500,6500,7500,8500,9500,10500, 12000, 13000];

export const ScreenMission = (props: any) => {

  const [stage, setStage] = useState(0);

  useEffect(()=> {
    const times = (props.location.state?.from === undefined) ? regTimes.map(time=>(.01*time)) : regTimes;
    times.forEach((time,idx) => {    setTimeout(() => {setStage(idx+1)}, times[idx]);
  })

  }, [props.location.state?.from])

  let lastStage=0;

  return (
    <styled.SecondScreen>

      <styled.StagedSpan show={stage >lastStage++} >
        <styled.BoldSpan>W</styled.BoldSpan>e’re designing a new email system <styled.StagedSpan show={stage > lastStage++} >from the ground up.</styled.StagedSpan>
      </styled.StagedSpan>


      <styled.StagedSpan show={stage>lastStage++}>
        To address these <styled.Link to="/issues">foundational issues</styled.Link>.
      </styled.StagedSpan>
      <styled.StagedSpan  show={stage>lastStage++}>
        And to keep things <a href="https://en.wikipedia.org/wiki/KISS_principle" rel="noopener noreferrer" target="_blank">simple, stupid</a>.
      </styled.StagedSpan>

      <styled.Spacer /> 


      <styled.StagedSpan show={stage>lastStage++}> 
        <styled.BoldSpan>W</styled.BoldSpan>ho are we?  We are <styled.StagedSpan show={stage>lastStage++}> <img alt="control" src="/logo.svg" /><styled.LogoColorSpan>freaks</styled.LogoColorSpan>.</styled.StagedSpan>
      </styled.StagedSpan>
      <styled.StagedSpan show={ stage>lastStage++ } > 
        And we're taking <img src="/logo.svg" alt="control" /><styled.LogoColorSpan>back</styled.LogoColorSpan> from scammers, <styled.StagedSpan show={stage>lastStage++}>spammers, </styled.StagedSpan><styled.StagedSpan show={stage>lastStage++}>untrustworthy companies, </styled.StagedSpan><styled.StagedSpan show={stage>lastStage++}>and even people we know.</styled.StagedSpan>
      </styled.StagedSpan>   

      <styled.Spacer /> 

      <styled.StagedSpan show={stage>lastStage++}>
        <styled.BoldSpan>B</styled.BoldSpan>eta launch in 2022.  <styled.StagedSpan show={stage>lastStage++}><styled.Link to="/notify">Get notified</styled.Link>.</styled.StagedSpan>
      </styled.StagedSpan>

    </styled.SecondScreen>
  )
}

/*

      <styled.SmallSpan><styled.BoldSpan>{`{`}</styled.BoldSpan>  to | from | cc | bcc | inbox | sent | junk | spam  <styled.BoldSpan>{`}`}</styled.BoldSpan></styled.SmallSpan> 
      <span><styled.BoldSpan>L</styled.BoldSpan>ook familiar? Since webmail was <a href="https://en.wikipedia.org/wiki/Outlook.com" target="_blank">popularized</a> in 1996, little has changed.</span>
      <span> </span>
      <span> </span>

      */