import * as styled from "./styled";

export const ScreenConfirm = () => {

  return (
    <styled.ConfirmScreen>
       <span>Thank you for your interest.</span>
        
       <span>Help is on the way.</span>

       <span>In the meantime, feel free to contact us at <a href="mailto: info@ctrl.email">info@ctrl.email</a>.</span>

    </styled.ConfirmScreen>
  )
}