import {Header} from "./Header";

import * as styled from "./styled";
import { Switch, Route  } from "react-router-dom";
import {ScreenIntro} from "./ScreenIntro";
import {ScreenMission} from "./ScreenMission";
import {ScreenConfirm} from "./ScreenConfirm";
import {ScreenIssues} from "./ScreenIssues";
import {ScreenNotify} from "./ScreenNotify";
import { useNewRouteHandler } from "global/useNewRouteHandler";

console.clear();


function App() {
  
  useNewRouteHandler();

  return (
    <div>
      <Header />
      <styled.Main>
      <Switch>
        <Route exact path="/" component={ScreenIntro} />
        <Route path="/mission" component={ScreenMission} />
        <Route path="/issues" component={ScreenIssues} />
        <Route path="/notify" component={ScreenNotify} />
        <Route path="/confirmation" component={ScreenConfirm} />
        <Route path="*" component = {ScreenIntro} />
      </Switch>    
      </styled.Main>
    </div>
  );
}

export default App;
